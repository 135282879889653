<template>
	<div>
		<div v-if="selected.length">
			<div
			v-for="category in selected"
			:key="category.id">
				<div
				class="category-title"
				:style="{ 'background-image': 'url(' + image(category) + ')' }">
					{{ category.name }}
				</div>
				<cards
				:show_created_at="false" 
				model_name="product"
				@clicked="toProduct"
				:models="category.products"></cards>
			</div>
		</div>
		<p 
		v-else
		class="text-with-icon m-b-150">
			<i class="icon-eye-slash"></i>
			No se encontraron productos			
		</p>
	</div>
</template>
<script>
import categories from '@/mixins/categories'
import buyer from '@/mixins/buyer'
export default {
	mixins: [categories, buyer],
	components: {
		Cards: () => import('@/common-vue/components/display/cards/Index'),
	},
}
</script>
<style lang="sass">
.category-title 
	background-repeat: no-repeat
	background-size: 100% auto
	background-position: center
	background-attachment: fixed
	color: #FFF
	font-size: 2em
	font-weight: bold
	text-shadow: 2px 2px black
	margin-bottom: 15px
	@media screen and (max-width: 768px)
		padding: 1em 0
	@media screen and (min-width: 768px)
		padding: 2em 0
</style>