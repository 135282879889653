<template>
	<b-row
	class="p-t-15">
		<b-col
		md="11"
		lg="10"
		xl="9">
			<search-component></search-component>
			<categories-with-products></categories-with-products>
		</b-col>
	</b-row>
</template>
<script>
import SearchComponent from '@/components/buyer/products/components/SearchComponent'
import CategoriesWithProducts from '@/components/buyer/products/components/CategoriesWithProducts'
export default {
	components: {
		SearchComponent,
		CategoriesWithProducts,
	},
}
</script>