<template>
	<div 
	class="m-b-15">
		<b-form-input
		id="search-component"
		@keydown="search"
		v-model="query"
		placeholder="Buscar un producto"></b-form-input>
	</div>
</template>
<script>
export default {
	data() {
		return {
			query: '',
		}
	},
	created() {
		if (!this.selected_categories.length) {
			this.$store.commit('category/setSelected', this.categories)
		}
	},
	computed: {
		selected_categories() {
			return this.$store.state.category.selected
		},
		categories() {
			return this.$store.state.category.models
		},
	},
	methods: {
		search() {
			if (this.query.length > 2) {
				let categories_to_show = []
				this.categories.forEach(category => {
					category.products.forEach(product => {
						if (product.name.toLowerCase().includes(this.query.toLowerCase())) {
							let index = categories_to_show.findIndex(cat => {
								return cat.id == category.id 
							})
							if (index == -1) {
								let category_to_show = {
									...category,
									products: [
										product
									]
								}
								categories_to_show.push(category_to_show)
							} else {
								categories_to_show[index].products.push(product)
							}
						}
					})
				})
				console.log(categories_to_show)
				this.$store.commit('category/setSelected', categories_to_show)
			} else {
				this.$store.commit('category/setSelected', [])
			}
		}
	}
}
</script>
<style lang="sass">
// .search-component
// 	background: #FFF 
// 	width: 100%
</style>